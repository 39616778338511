<template>
  <v-container
    fluid
    class="mx-0 my-0 px-4 py-5"
    style="background: #E6E6E6;"
  >
    <v-row class="mt-4 ml-4">
      <v-col class="px-0 py-0">
        <div class="d-flex align-items-center">
          <v-icon
            style="
            color: #7A1878;
            cursor: pointer;
          "
            @click="navBack"
          >
            mdi-chevron-left
          </v-icon>
          <span
            class="font-common"
            style="
            color: #7A1878;
            cursor: pointer;
          "
            @click="navBack"
          >
            Kembali
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row class="ml-5">
      <v-col class="px-0 py-0">
        <div>
          <span
            class="font-common"
            style="
            font-size: 24px;
            color: #333333;
            "
          >
            Detail Pesanan
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row class="mx-5">
      <v-col class="px-0 py-0">
        <div>
          <v-card
            class="px-6 py-2"
            elevation="0"
          >
            <v-row>
              <v-col
                cols="12"
                sm="2"
                class="font-common"
              >
                No. Pesanan
              </v-col>
              <v-col
                cols="12"
                sm="2"
                class="font-common px-0"
                style="font-weight: bold;"
              >
                {{ assetDetail.code }}
              </v-col>
              <v-col
                cols="12"
                sm="2"
                class="font-common"
              >
                Durasi Tayang
              </v-col>
              <v-col
                cols="12"
                sm="2"
                class="font-common px-0"
                style="font-weight: bold;"
              >
                {{ assetDetail.total_days + ' Hari' }}
              </v-col>
              <v-col
                cols="12"
                sm="2"
                class="font-common"
              >
                Total Pembayaran
              </v-col>
              <v-col
                cols="12"
                sm="2"
                class="font-common px-0"
                style="font-weight: bold;"
              >
                {{ 'Rp ' + assetDetail.total_amount | dotThousandSeparator }}
              </v-col>
            </v-row>

            <v-row class="mt-n2">
              <v-col
                cols="12"
                sm="2"
                class="font-common"
              >
                No. Asset
              </v-col>
              <v-col
                cols="12"
                sm="2"
                class="font-common px-0"
                style="font-weight: bold;"
              >
                {{ assetDetail.ass_code }}
              </v-col>
              <v-col
                cols="12"
                sm="2"
                class="font-common"
              >
                Tanggal Pesan
              </v-col>
              <v-col
                cols="12"
                sm="2"
                class="font-common px-0"
                style="font-weight: bold;"
              >
                13 April 2021
              </v-col>
              <v-col
                cols="12"
                sm="2"
                class="font-common"
              >
                Status Pesanan
              </v-col>
              <v-col
                cols="12"
                sm="2"
                class="font-common px-0"
                style="font-weight: bold;"
              >
                Pembayaran Diterima
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <hr style="border: 1px solid #E6E6E6;">
              </v-col>
            </v-row>

            <v-row>
              <v-col style="font-weight: bold; color: #333333;">
                Faktur Pajak Media Mitra
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="pt-0"
              >
                <template v-if="assetDetail.tax_inv_prt_file.name === null">
                  <span class="font-common">
                    Mitra belum upload.
                  </span>
                </template>
                <template v-else>
                  <div
                    class="font-common"
                    style="cursor: pointer; display: inline-block;"
                    @click="downloadFile(assetDetail.tax_inv_prt_file.link)"
                  >
                    <span>
                      {{ assetDetail.tax_inv_prt_file.name + ' (' + assetDetail.tax_inv_prt_file.size + ')' }}
                    </span>
                    <span>
                      <v-icon>
                        mdi-download-circle
                      </v-icon>
                    </span>
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-row class="mx-5">
      <v-col class="px-0 py-0">
        <div>
          <v-card
            class="px-6 py-2"
            elevation="0"
          >
            <v-row>
              <v-col style="font-weight: bold; color: #333333;">
                Faktur Pajak Media Travikr
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <template v-if="tmpData.image === null">
                  <div
                    style="
                    padding: 10px;
                    height: 46px;
                    background: #FAFAFA;
                    border: 1px dashed #E6E6E6;
                    box-sizing: border-box;
                    border-radius: 4px;
                    text-align: center;
                    color: #E6E6E6;
                  "
                  >
                    Masukkan file atau foto faktur pajak
                  </div>
                </template>
                <template v-else>
                  <div
                    class="font-common pt-2"
                    style="cursor: pointer; display: inline-block;"
                    @click="downloadFile(tmpData.image.file_path)"
                  >
                    <span>
                      {{ tmpData.image.file_name + ' (' + tmpData.image.file_size + ')' }}
                    </span>
                    <span>
                      <v-icon>
                        mdi-download-circle
                      </v-icon>
                    </span>
                  </div>
                </template>
              </v-col>

              <v-col
                v-if="assetDetail.tax_inv_trv_can_upload"
                cols="12"
                sm="4"
              >
                <vue-dropzone
                  id="dropzone"
                  ref="myVueDropzone"
                  class=""
                  style="display: inline-block;"
                  :use-custom-slot="true"
                  :options="dropzoneOptions"
                  @vdropzone-file-added="vdropzoneFileAdded"
                >
                  <div
                    style="
                    padding: 10px;
                    height: 46px;
                    background: #FFFFFF;
                    border: 1px solid #E6E6E6;
                    box-sizing: border-box;
                    border-radius: 4px;
                    text-align: center;
                    cursor: pointer;
                    "
                  >
                    <span
                      class="font-common"
                      style="
                    font-weight: bold;
                    color: #7A1878;"
                    >
                      Upload File
                    </span>
                  </div>
                </vue-dropzone>
              </v-col>
            </v-row>

            <v-row v-if="assetDetail.tax_inv_trv_can_upload">
              <v-col
                cols="12"
                sm="4"
              >
                <v-btn
                  color="purple"
                  block
                  @click="saveUploadedFile"
                >
                  <span
                    class="text-capitalize"
                    style="
                    font-weight: bold;
                    color: #FFFFFF;"
                  >
                    Simpan Faktur Pajak
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-row class="mx-5">
      <v-col class="px-0 py-0">
        <div>
          <v-card
            class="px-6 py-2"
            elevation="0"
          >
            <v-row>
              <v-col style="font-weight: bold; color: #333333;">
                Periode Tayang
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          No.Produksi
                        </th>
                        <th class="text-left">
                          Periode Tayang
                        </th>
                        <th class="text-left">
                          Durasi
                        </th>
                        <th class="text-left">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(v, i) in assetDetail.period"
                        :key="i"
                      >
                        <td
                          class="font-common"
                          style="
                          font-style: italic;
                          font-weight: bold;
                          color: #7A1878;
                          "
                        >
                          {{ v.prod_code }}
                        </td>
                        <td class="font-common">
                          {{ v.start_date + ' - ' + v.end_date }}
                        </td>
                        <td class="font-common">
                          {{ v.duration + ' Hari' }}
                        </td>
                        <td class="font-common">
                          {{ v.prod_status }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-row class="mx-5">
      <v-col class="px-0 py-0">
        <div>
          <v-card
            class="px-6 py-2"
            elevation="0"
          >
            <v-row>
              <v-col style="font-weight: bold; color: #333333;">
                Rincian Transaksi
              </v-col>
            </v-row>

            <v-row class="ml-4">
              <v-col
                class="font-common px-0"
                cols="12"
                sm="2"
                style="font-weight: bold;"
              >
                {{ assetDetail.payout_detail[0].text }}
              </v-col>
              <v-col
                class="font-common px-0"
                cols="12"
                sm="1"
                style="font-weight: bold;"
              >
                Rp
              </v-col>
              <v-col
                class="font-common text-right px-0"
                cols="12"
                sm="1"
                style="font-weight: bold;"
              >
                {{ assetDetail.payout_detail[0].val | dotThousandSeparator }}
              </v-col>
            </v-row>

            <v-row class="ml-4">
              <v-col
                class="font-common px-0"
                cols="12"
                sm="2"
                style="font-weight: bold;"
              >
                {{ assetDetail.payout_detail[1].text }}
              </v-col>
              <v-col
                class="font-common px-0"
                cols="12"
                sm="1"
                style="font-weight: bold;"
              >
                Rp
              </v-col>
              <v-col
                class="font-common text-right px-0"
                cols="12"
                sm="1"
                style="font-weight: bold; color: #EA3E3A;"
              >
                {{ '-' + assetDetail.payout_detail[1].val | dotThousandSeparator }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="5"
              >
                <hr style="border: 1px solid #E6E6E6;">
              </v-col>
            </v-row>

            <v-row class="ml-4">
              <v-col
                class="font-common px-0"
                cols="12"
                sm="2"
                style="font-weight: bold;"
              >
                {{ assetDetail.payout_detail[2].text }}
              </v-col>
              <v-col
                class="font-common px-0"
                cols="12"
                sm="1"
                style="font-weight: bold;"
              >
                Rp
              </v-col>
              <v-col
                class="font-common text-right px-0"
                cols="12"
                sm="1"
                style="font-weight: bold; color: #7A1878;"
              >
                {{ assetDetail.payout_detail[2].val | dotThousandSeparator }}
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import vue2Dropzone from 'vue2-dropzone'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      vueDropzone: vue2Dropzone,
    },

    data: () => ({
      // Section Data General
      assetDetail: {
        ass_code: '',
        hash: '',
        id: 1,
        code: '',
        status: 2,
        status_string: '',
        start_date: '',
        ass_id: 1,
        ass_type: '',
        ass_name: '',
        total_amount: 0,
        total_days: 0,
        tax_inv_prt_file: {
          link: null,
          name: null,
          size: '0Kb',
        },
        tax_inv_trv_file: {
          link: null,
          name: null,
          size: '0Kb',
        },
        payout_detail: [
          {
            line: false,
            payment_reff: null,
            text: 'Total Penjualan',
            val: 9827210,
          },
          {
            line: false,
            payment_reff: null,
            text: 'Total Penjualan',
            val: 9827210,
          },
          {
            line: false,
            payment_reff: null,
            text: 'Total Penjualan',
            val: 9827210,
          },
        ],
        period: [],
        tax_inv_trv_can_upload: true,
        created_time: '',
      },

      // Section Periode Tayang
      periodeTayang: [],

      // Section Dropzone
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        autoQueue: false,
        autoProcessQueue: false,
        maxFiles: 1,
      },

      // Section temporary data
      tmpData: {
        image: null,
      },

      // Section Pagination
      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,
    }),

    computed: {},

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')
        // if (userMenus.includes('xxx')) {
        //   this.xxx = true
        // }

        const vm = this

        const requestBody = {
          order_hash: this.$route.params.id,
        }

        axios.post('/t/order/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data)
            const v = res.data.data.detail
            vm.assetDetail = v
            if (v.tax_inv_trv_file.name !== null) {
              vm.tmpData.image.file_name = v.tax_inv_trv_file.name
              vm.tmpData.image.file_size = v.tax_inv_trv_file.size
              vm.tmpData.image.file_path = v.tax_inv_trv_file.link
            }
            // console.log(vm.tmpData.image)
          }
        }).catch((e) => {
          console.log(e.response)
        })
      },

      navBack () {
        this.$router.push({ name: 'Order' })
      },

      saveUploadedFile () {
        const requestBody = {
          order_hash: this.$route.params.id,
          file_hash: this.tmpData.image.tmp_file_hash,
        }

        axios.post('/t/order/submitTax', requestBody).then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data)
            this.$toast.success(res.data.message)
            this.initialize()
          }
        }).catch((e) => {
          console.log(e.response)
        })
      },

      downloadFile (link) {
        window.open(link, '_blank')
      },

      vdropzoneFileAdded (file) {
        const dropzoneFiles = this.$refs.myVueDropzone.dropzone.files
        if (dropzoneFiles.length > 0) {
          dropzoneFiles.shift()
          if (document.querySelector('.dz-preview')) document.querySelector('.dz-preview').remove()
          if (document.querySelector('.dz-image-preview')) document.querySelector('.dz-image-preview').remove()
        }

        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('file', file)
        requestBody.append('dest', 'orderTaxTrv')

        axios.post(baseCDNurl + '/upload', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data)
            document.querySelectorAll('.dz-details').forEach(el => el.remove())
            document.querySelectorAll('.dz-progress').forEach(el => el.remove())
            this.tmpData.image = res.data.data
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #808080;
}
</style>
